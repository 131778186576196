import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { ArrowInclined } from "../atoms/Icons"
import Layout from "../components/layout"

const OurArtPage = () => {
  return (
    <div className="">
      <Layout>
        <h1 className="mobile-header lg:hidden">Our Art</h1>
        <h1 className="hidden lg:block lg:desktop-header">Our Art Gallery</h1>
        <p className="mobile-body lg:dektop-body py-4">
          About a year ago I was sitting up in the palapa watching Bob paint.
          Looking around the room, I saw stacks of work from our 20 years
          together. I thought: Shit why aren’t we turning all this stuff into
          Coffee mug art and tee shirts and what not. Why in the hell are we
          still going to work doing drywall, production, cooking and what ever
          else comes our way? <br />
          I said : “Self? We may never be seen at the Metropolitan Museum of
          Art, but this could be really fun wearable art.”
          <br />
          Right then I got brave, posting our art on Instagram. The next day my
          girlfriend Veronica called. She said she adored my art and where could
          she go to order some of my drawings as napkins, and coffee mugs and
          tote bags. She had many friends she thought would love our art and
          where was our website, so she could start ordering?
          <br />
          Well Thank You Missy V- You single handedly lit a firecracker under
          our back sides and got us moving! You are our seed of inspiration, by
          sharing that you loved what we do, you have given us the courage to
          open
          <strong> Barefoot Artes.</strong>
          <br />
          To you we toast.
          <br />
          <p>
            And… all ya alls out there?… Might you be the whisper in someone’s
            ear, that takes them from fear to courage and sets them on their way
            to sharing their artistic gifts with the world.
          </p>
          <br />
          Te Amamos Veronica. <strong> Animo!!!!!</strong>
          <br />
          Xoxoxoxo BBQ BOB and Missy D
        </p>

        <div className="py-2 max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden  pl-1 flex gap-4 items-start">
          <div>
            <StaticImage
              height={399}
              src="../images/inspiration/inspiration.png"
              alt="Inspiration"
              placeholder="tracedSVG"
              layout="fixed"
              className="flex-shrink-0"
            />
            <Link
              className="py-2 flex items-center font-bold my-4 whitespace-nowrap"
              to="/inspiration"
            >
              <ArrowInclined />
              Check out our inspiration
            </Link>
          </div>
          <div>
            <StaticImage
              height={331}
              src="../images/bob-gallery/IMG_8102.png"
              alt="Bob artwork"
              placeholder="tracedSVG"
              layout="fixed"
              className="flex-shrink-0"
            />
            <Link
              className="py-2 flex items-center font-bold my-4"
              to="/bob-gallery"
            >
              <ArrowInclined />
              Visit Bob's ArtWork
            </Link>
          </div>
          <div>
            <StaticImage
              height={399}
              src="../images/inspiration/inspiration3.png"
              alt="Dana artwork"
              placeholder="tracedSVG"
              layout="fixed"
              className="flex-shrink-0"
            />
            <Link
              className="py-2 flex items-center font-bold my-4"
              to="/dana-gallery"
            >
              <ArrowInclined />
              Visit Dana's ArtWork
            </Link>
          </div>
          <div>
            <StaticImage
              height={339}
              src="../images/inspiration/inspiration2.png"
              alt="Dana's photography"
              placeholder="tracedSVG"
              layout="fixed"
              className="flex-shrink-0"
            />
            <Link
              className="py-2 flex items-center font-bold my-4"
              to="/photography"
            >
              <ArrowInclined />
              Dana’s photography
            </Link>
          </div>
          <div>
            <StaticImage
              height={331}
              src="../images/inspiration/inspiration4.png"
              alt="our music"
              placeholder="tracedSVG"
              layout="fixed"
              className="flex-shrink-0"
            />
            <Link className="py-2 flex items-center font-bold my-4" to="/music">
              <ArrowInclined />
              Our Music
            </Link>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default OurArtPage
